<template>
  <div>
    <div
        v-for="(object, idx) in cObjects"
        :key="idx"
        :style="object.style"
        :class="['collage-object', object.compClass, { '--editable': idx === idxEditableObject }]"
        @click="set(idx)"
    >
      <img class="collage-object-img" :src="object.imgPath" alt=""/>
    </div>
  </div>
</template>

<script>

export default {
  name: "CollageObject",
  components: {},
  props: ['objects', 'idxEditableObject', 'editable', 'device', 'top'],
  computed: {
    cObjects: function () {
      if (!this.objects[this.device]) return [];
      return this.objects[this.device].map((object, idx) => {

        object.end.top = parseInt(object.end.top)
        object.end.x = parseInt(object.end.x)
        object.end.y = parseInt(object.end.y)
        object.end.r = parseInt(object.end.r)
        object.end.h = parseInt(object.end.h)
        object.end.w = parseInt(object.end.w)
        object.start.top = parseInt(object.start.top)
        object.start.x = parseInt(object.start.x)
        object.start.y = parseInt(object.start.y)
        object.start.r = parseInt(object.start.r)
        object.start.h = parseInt(object.start.h)
        object.start.w = parseInt(object.start.w)

        const top = parseInt(this.top);
        const figure = {
          ...object,
          id: idx,
          type: object.type,
          top: object.start.top,
          compClass: object.class.map(c => "--" + c),
        };

        if (object.start.top > top) {
          figure.style = this.getStyle(object.start);
        } else if (object.start.top <= top && object.end.top >= top) {
          const p = (top - object.start.top) / (object.end.top - object.start.top);
          const diffX = (object.end.x - object.start.x) * p + object.start.x;
          const diffY = (object.end.y - object.start.y) * p + object.start.y;
          const diffR = (object.end.r - object.start.r) * p + object.start.r;
          const diffW = (object.end.w - object.start.w) * p + object.start.w;
          const diffH = (object.end.h - object.start.h) * p + object.start.h;
          figure.style = this.getStyle({x: diffX, y: diffY, r: diffR, w: diffW, h: diffH});
        } else {
          figure.style = this.getStyle(object.end);
        }

        return figure;
      });
    },
  },
  methods: {
    set: function (idx) {
      if (!this.editable) return;
      this.$emit('set', idx)
    },
    getStyle: function (data) {
      return {
        transform: `translate(${data.x}px, ${data.y}px) rotate(${data.r}deg)`,
        height: `${data.h}px`,
        width: `${data.w}px`,
      };
    },
  }
}
</script>

<style lang="sass">
.collage-object
  width: 100px
  height: 100px
  position: absolute
  z-index: 100
  transition: all 0.1s linear

  &:before
    font-size: 60px
    background: #403f3f
    width: 100%
    height: 100%
    text-align: center
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    border-radius: 5px
    opacity: 0.6
    color: #fff

  &.--editable
    border: 1px solid #9d0e0e
    z-index: 10000
    opacity: 0.7

  &.--start
    z-index: 10100

    &:before
      content: 'S'

  &.--end
    z-index: 10100

    &:before
      content: 'E'

  &.--left
    left: 0

  &.--top
    top: 0

  &.--bottom
    bottom: 0

  &.--right
    right: 0

  &-drag
    position: absolute
    width: 100%
    height: 100%
    z-index: 1

  &-img
    width: 100%
    height: 100%

</style>