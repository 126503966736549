<template>
  <div :class="['c-btn', `--${variant}`]" @click="$emit('click')">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "VButton",
  props: ["variant"],
};
</script>

<style lang="sass">
.c-btn
  text-align: center
  padding: 15px 20px
  display: block
  max-width: 320px
  min-width: 140px
  margin: 1.25rem auto
  background: #2DBF5E
  color: #ffffff
  font-size: 15px
  border-radius: 2rem
  text-transform: uppercase
  font-weight: bold
  transition: opacity 500ms ease-in-out, bottom 400ms ease-in-out
  text-decoration: none

  a
    color: inherit
    text-decoration: none

  &:hover
    background: #23a662
    cursor: pointer
    color: #ffffff
    text-decoration: none

  &.--block
    width: 100%

  &.--white
    background: #fff
    color: #000000

    &:hover
      background: #fff
      color: #2DBF5E

  &.--transparent
    background: transparent
    color: #fff
    border: 2px solid #fff

    &:hover
      cursor: pointer
      background: #fff
      color: #2DBF5E
</style>
