<template>
  <div class="player" @click.prevent="togglePlay">
    <div :class="{ playing: playing }" class="icon centered"></div>
    <audio
        ref="audio"
        :loop="innerLoop"
        :src="file"
        preload="auto"
        style="display: none"
        @play="playing = true"
        @pause="playing = false"
    ></audio>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "APlayer",
  props: {
    file: {
      type: String,
      default: null,
    },
    autoPlay: {
      type: Boolean,
      default: false,
    },
    loop: {
      type: Boolean,
      default: false,
    },
    play: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    currentSeconds: 0,
    durationSeconds: 0,
    innerLoop: true,
    playing: false,
    startPlay: false,
    volume: 100,
  }),
  computed: {
    ...mapState({
      stopAudio: state => state.stopAudio,
    }),
  },
  watch: {
    stopAudio(val) {
      if (this.playing) {
        this.pauseAudio()
      }
    },
  },
  methods: {
    togglePlay: function () {
      if (this.playing)
        this.pauseAudio();
      else
        this.playAudio()
    },
    playAudio: function () {
      this.$store.commit("setStopAudio");
      this.$refs.audio.play();
    },
    pauseAudio: function () {
      this.$refs.audio.pause();
      this.$refs.audio.currentTime = 0
    }
  },
};
</script>

<style lang="sass" scoped>
.player
  position: absolute
  background: #2CBB71
  width: 4.375rem
  height: 4.375rem
  border-radius: 50%
  border: 0.5rem solid #76E9AE

  &:hover
    background: #009a50
    cursor: pointer

  .icon
    width: 2.5rem
    height: 2.5rem
    background: url("/img/icon__audio-play.svg") 5px center no-repeat
    background-size: 80% 80%

    &.playing
      background: url("/img/icon__audio-pause.svg") center center no-repeat
      background-size: contain
</style>

