<template>
  <div class="memory" v-if="collage">
    <collage-background
        :collage="{bgPath: collage.bgPath, bgPathMobile: collage.bgPathMobile}"
        :device="device"
    >
    </collage-background>
    <collage-object
        :objects="collage.objects"
        :device="device"
        :top="top"
    >
    </collage-object>
    <c-phone>
      <v-scroller v-model="top" :ext="800" @end="isEnd = true"></v-scroller>
      <collage-content
          :content="collage.texts"
          :isEnd="isEnd"
          :top="top"
          @back="back()"
      >
        {{ $route.query.branch ? 'Вернуться в ЧАТ' : 'Назад' }}
      </collage-content>
    </c-phone>
  </div>
</template>

<script>
import CPhone from "@/components/phone";
import api from "@/api";
import VScroller from "@/components/v-scroller";
import CollageContent from "@/components/collage/collage-content";
import CollageBackground from "@/components/collage/collage-background";
import CollageObject from "@/components/collage/collage-object";
import {mapState} from "vuex";

export default {
  name: "MemoryPage",
  components: {CollageObject, CollageBackground, CollageContent, VScroller, CPhone},
  props: ['id', 'backStep'],
  data: function () {
    return {
      collage: null,
      top: 0,
      device: 'desktop',
      isEnd: false
    }
  },
  async mounted() {
    this.collage = await api.getCollage(this.id);
    this.$store.commit("setBackground", "page");
    this.$store.commit("setShowPhoneOpacity", false);
    window.addEventListener('resize', this.detectDevice)
    this.detectDevice()
    document.title = 'CONVERTER. Воспоминания. ' + this.collage.name
  },
  methods: {
    detectDevice: function () {
      const w = document.querySelector('body').clientWidth
      if (w < 600)
        this.device = 'mobile'
      else if (w < 1200)
        this.device = 'tablet'
      else
        this.device = 'desktop'
    },
    back: function () {
      if (this.$route.query.branch) {
        this.$router.push({
          name: 'chat',
          params: {
            ...this.$route.query,
            step: +this.$route.query.step + 1
          }
        })
      } else
        this.$router.push({name: 'memories'})
    }
  }
};
</script>

<style lang="sass">

</style>
