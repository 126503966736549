<template>
  <div id="scrollable" class="collage-content" :class="{'--editable': idxEditableContent}" :style="{ top: '-' + top + 'px' }">
    <div :class="{ hidden: 1 }" class="collage-content__info">
      <p>
        Родители,<br/>
        которые были<br/>
        подростками
      </p>
      <div class="collage-content__down">
        <img src="/img/icon_scroll.svg" alt=""/>
        <span>Листайте вниз</span>
      </div>
    </div>
    <div class="collage-content__item"
        v-for="(item, idx) in content"
        :key="idx"
        :class="{ '--editable': idx === idxEditableContent }"
        :style="{ marginTop: item.top + 'px' }"
        @click.stop="set(idx)"
    >
      <div class="collage-content__delete" @click="del(idx)" v-if="editable">
        <i class="far fa-trash-alt"></i>
      </div>
      <template v-if="typeof item.sound === 'undefined'">
        <div
            class="collage-content__text"
        >{{ item.content }}
        </div>
      </template>
      <template v-else>
        <a-player :file="item.content"
          :style="{ marginLeft: item.left + 'px' }"
        ></a-player>
      </template>
    </div>
    <v-button
        class="collage-content__action"
        :class="{'--active': isEnd}"
        @click="$emit('back')"
    >
      <slot></slot>
    </v-button>
  </div>
</template>

<script>
import VButton from "@/components/basic/v-button";
import APlayer from "@/components/a-player";

export default {
  name: "collage-content",
  components: {APlayer, VButton},
  props: ['content', 'idxEditableContent', 'editable', 'isEnd', 'top'],
  methods: {
    set: function (idx) {
      if (!this.editable) return;
      this.$emit('set', idx)
    },
    del: function (idx) {
      if (!this.editable) return;
      this.$emit('delete', idx)
    }
  }
}
</script>

<style lang="sass">
.collage-content
  width: 100%
  height: 100%
  position: relative
  margin-bottom: 100px
  transition: top 0.1s linear

  &__item
    position: relative

  &__text
    background: #fff
    padding: 1.2rem
    margin: 0 auto 100px
    width: 90%
    position: relative
    white-space: break-spaces

  &.--editable
    &.collage-content__text:hover
      cursor: pointer

    &.--editable
      background: #ececec

  &__delete
    position: absolute
    top: -35px
    right: 0
    background: #1f5bff
    padding: 5px 10px
    color: #fff

  &__info
    height: 40rem
    padding-top: 8rem
    width: 100%
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.6) 50%, rgba(0, 0, 0, 0) 100%)
    opacity: 1
    text-align: center
    font-weight: bold
    color: #ffffff

    p
      font-size: 1.4rem
      line-height: 140%
      margin-bottom: 3rem

  &__down
    opacity: 0.7

    span
      font-size: 0.9rem
      margin-top: 1rem
      display: block
      text-transform: uppercase

  &__action
    position: fixed
    bottom: 20px
    width: 90%
    left: 50%
    transform: translateX(-50%)
    opacity: 0
    transition: opacity 300ms ease-in, bottom 300ms ease-in
    z-index: 1

    &.--active
      bottom: 40px
      opacity: 1

</style>