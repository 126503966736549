<template>
  <div class="collage-background" :style="style"></div>
</template>
<script>

export default {
  name: "CollageBackground",
  props: ['collage', 'device'],
  computed: {
    style: function () {
      if (this.device === 'desktop' || this.device === 'tablet') {
        return this.collage.bgPath
            ? {backgroundImage: "url(" + this.collage.bgPath + ")"}
            : null;
      } else {
        return this.collage.bgPathMobile
            ? {backgroundImage: "url(" + this.collage.bgPathMobile + ")"}
            : null;
      }
    },
  }
}
</script>

<style lang="sass">
.collage-background
  background-repeat: no-repeat
  background-size: cover
  position: absolute
  width: 100%
  height: 100%
</style>